var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"status-icon":"","rules":_vm.rules,"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"排序：","prop":"sort"}},[_c('el-input',{attrs:{"type":"number","min":1},model:{value:(_vm.ruleForm.sort),callback:function ($$v) {_vm.$set(_vm.ruleForm, "sort", $$v)},expression:"ruleForm.sort"}})],1),_c('el-form-item',{attrs:{"label":"短视频上传：","prop":"video"}},[_c('ReadyUploadSource',{attrs:{"path":_vm.ruleForm.video,"type":"video","showStyle":{
                    width: '150px',
                    height: '80px',
                },"maxSize":1024 * 1024 * 20},on:{"getSource":function (val) { return (_vm.ruleForm.video = val.path); },"removeThis":function($event){_vm.ruleForm.video = ''}}}),_c('div',{staticClass:"tips"},[_vm._v("建议视频尺寸750*400px，大小不超过20M")])],1),_c('el-form-item',{attrs:{"label":"短视频封面：","prop":"cover_picture"}},[_c('ReadyUploadSource',{attrs:{"path":_vm.ruleForm.cover_picture,"showStyle":{
                    width: '150px',
                    height: '80px',
                },"maxSize":1024 * 1024},on:{"getSource":function (val) { return (_vm.ruleForm.cover_picture = val.path); },"removeThis":function($event){_vm.ruleForm.cover_picture = ''}}}),_c('div',{staticClass:"tips"},[_vm._v("建议视频尺寸750*400px，大小不超过1M")])],1),_c('el-form-item',{attrs:{"label":"短视频标题：","prop":"title"}},[_c('el-input',{attrs:{"placeholder":"请填写短视频标题"},model:{value:(_vm.ruleForm.title),callback:function ($$v) {_vm.$set(_vm.ruleForm, "title", $$v)},expression:"ruleForm.title"}})],1),_c('el-form-item',{attrs:{"label":"短视频分类：","prop":"classify_id"}},[_c('el-select',{model:{value:(_vm.ruleForm.classify_id),callback:function ($$v) {_vm.$set(_vm.ruleForm, "classify_id", $$v)},expression:"ruleForm.classify_id"}},_vm._l((_vm.classify_list),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.classify_name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"短视频二维码：","prop":"qrcode"}},[_c('ReadyUploadSource',{attrs:{"path":_vm.ruleForm.qrcode,"showStyle":{
                    width: '80px',
                    height: '80px',
                },"maxSize":1024 * 1024},on:{"getSource":function (val) { return (_vm.ruleForm.qrcode = val.path); },"removeThis":function($event){_vm.ruleForm.qrcode = ''}}}),_c('div',{staticClass:"tips"},[_vm._v("建议视频尺寸750*400px，大小不超过1M")])],1),_c('el-form-item',{attrs:{"label":"虚拟查看数量："}},[_c('el-input',{attrs:{"type":"number","min":1},model:{value:(_vm.ruleForm.virtual_view),callback:function ($$v) {_vm.$set(_vm.ruleForm, "virtual_view", $$v)},expression:"ruleForm.virtual_view"}})],1),_c('el-form-item',{attrs:{"label":"虚拟点赞数量："}},[_c('el-input',{attrs:{"type":"number","min":1},model:{value:(_vm.ruleForm.virtual_like),callback:function ($$v) {_vm.$set(_vm.ruleForm, "virtual_like", $$v)},expression:"ruleForm.virtual_like"}})],1),_c('el-form-item',{attrs:{"label":"是否显示：","prop":"is_show"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.ruleForm.is_show),callback:function ($$v) {_vm.$set(_vm.ruleForm, "is_show", $$v)},expression:"ruleForm.is_show"}})],1)],1),_c('Preservation',{on:{"preservation":_vm.Preservation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }