<template>
    <el-main>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
            <el-form-item label="排序：" prop="sort">
                <el-input type="number" v-model="ruleForm.sort" :min="1"></el-input>
            </el-form-item>
            <el-form-item label="短视频上传：" prop="video">
                <ReadyUploadSource :path="ruleForm.video" @getSource="val => (ruleForm.video = val.path)"
                    @removeThis="ruleForm.video = ''" type="video" :showStyle="{
                        width: '150px',
                        height: '80px',
                    }" :maxSize="1024 * 1024 * 20"></ReadyUploadSource>
                <div class="tips">建议视频尺寸750*400px，大小不超过20M</div>
            </el-form-item>
            <el-form-item label="短视频封面：" prop="cover_picture">
                <ReadyUploadSource :path="ruleForm.cover_picture" @getSource="val => (ruleForm.cover_picture = val.path)"
                    @removeThis="ruleForm.cover_picture = ''" :showStyle="{
                        width: '150px',
                        height: '80px',
                    }" :maxSize="1024 * 1024"></ReadyUploadSource>
                <div class="tips">建议视频尺寸750*400px，大小不超过1M</div>
            </el-form-item>
            <el-form-item label="短视频标题：" prop="title">
                <el-input placeholder="请填写短视频标题" v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="短视频分类：" prop="classify_id">
                <el-select v-model="ruleForm.classify_id">
                    <el-option v-for="(item, index) in classify_list" :key="index" :label="item.classify_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="短视频二维码：" prop="qrcode">
                <ReadyUploadSource :path="ruleForm.qrcode" @getSource="val => (ruleForm.qrcode = val.path)"
                    @removeThis="ruleForm.qrcode = ''" :showStyle="{
                        width: '80px',
                        height: '80px',
                    }" :maxSize="1024 * 1024"></ReadyUploadSource>
                <div class="tips">建议视频尺寸750*400px，大小不超过1M</div>
            </el-form-item>
            <el-form-item label="虚拟查看数量：">
                <el-input type="number" v-model="ruleForm.virtual_view" :min="1"></el-input>
            </el-form-item>
            <el-form-item label="虚拟点赞数量：">
                <el-input type="number" v-model="ruleForm.virtual_like" :min="1"></el-input>
            </el-form-item>
            <el-form-item label="是否显示：" prop="is_show">
                <el-switch v-model="ruleForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
        </el-form>
        <Preservation @preservation="Preservation"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
export default {
    components: {
        Preservation,
        ReadyUploadSource,
    },
    data () {
        return {
            video_id: null,
            classify_list: [],
            ruleForm: {
                sort: 99,
                video: '',
                cover_picture: '',
                title: '',
                classify_id: null,
                qrcode: '',
                virtual_view: 0,
                virtual_like: 0,
                is_show: 1,
            },
            rules: {
                sort: [{ required: true, trigger: 'blur' }],
                video: [{ required: true, message: '请上传短视频', trigger: 'change' }],
                cover_picture: [{ required: true, message: '请上传短视频封面', trigger: 'change' }],
                title: [{ required: true, message: '请填写短视频标题', trigger: 'blur' }],
                classify_id: [{ required: true, message: '请选择短视频类型', trigger: 'change' }],
                qrcode: [{ required: true, message: '请上传短视频二维码', trigger: 'change' }],
                is_show: [{ required: true, trigger: 'change' }],
            },
        };
    },
    created () {
        if (this.$route.query.id) {
            this.video_id = this.$route.query.id;
            this.getVideoInfo();
        }
        this.getClassifyList();
    },
    methods: {
        getClassifyList () {
            this.$axios.post(this.$api.Tikmall.getVideoClassifyList).then(res => {
                if (res.code === 0) {
                    this.classify_list.push(...res.result);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getVideoInfo () {
            this.$axios
                .post(this.$api.Tikmall.getVideoInfo, {
                    id: this.video_id,
                })
                .then(res => {
                    if (res.code === 0) {
                        for (const key in this.ruleForm) {
                            this.ruleForm[key] = res.result[key];
                        }
                    }
                });
        },
        Preservation () {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let obj = _.cloneDeep(this.ruleForm);
                    let path = this.video_id ? this.$api.Tikmall.editVideoInfo : this.$api.Tikmall.addVideoInfo;
                    let str = this.video_id ? '修改成功' : '添加成功';
                    if (this.video_id) obj.id = this.video_id;
                    this.$axios.post(path, obj).then(res => {
                        if (res.code === 0) {
                            this.$message.success(str);
                            this.$router.push('/extension/crossWebsite/shortVideoList');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;
    padding-bottom: 80px;

    .el-form {

        .el-input,
        .el-select {
            width: 400px;
        }

        .tips {
            color: #a1a1a1;
            font-size: 12px;
        }
    }
}
</style>
